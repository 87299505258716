import { Component, Vue } from "vue-property-decorator";

import { SideNav } from "@/layout";
import { ConfirmDelete, Lightbox } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { QuizModel } from "@/models";
import { Common } from "../Common";

export class SearchForm {
    question = '';
    tid = '';
    difficulty = '';
    grade = '';
    level = '';
    unit = '';
    active = '';
}

type response = { [key: string]: unknown };

@Component<AdminQuizQuestionIndexController>({
    components: {
        SideNav, ConfirmDelete, Lightbox
    }
})
export default class AdminQuizQuestionIndexController extends Vue {
    private doubleClick = false;
    private searchForm = new SearchForm();
    private teacherList: object = {};
    private questionList: object = {};
    private gradeList: object = {};
    private levelList: object = {};
    private unitList: response = {};
    private num = 0;
    private pageHtml = "";
    private qqid = 0;
    private token = window.localStorage.getItem('adminToken') as string;

    private errorMsg = "";
    private openLB = false;
    private errNo!: number;

    public async created() {
        this.getTeacher();
        this.getQuestionList();
        this.gradeList = await Common.getGrade();
    }

    // 依照登入者身分列出可選老師
    private async getTeacher() {
        const identity = window.localStorage.getItem('admin_identity') as string;
        const sid = window.localStorage.getItem('admin_sid') as string;

        const item: { type: number, identity: string, sid: string, token: string } = {
            type: 2, //取全部
            identity: identity,
            sid: sid,
            token: this.token,
        }
        const data = await QuizModel.getTeacher(item) as response;
        Common.checkToken(data.ERR_CODE as number);
        this.teacherList = data.list as object;
    }

    private async getQuestionList() {
        const item: { [key: string]: string } = {
            page: this.$route.query.page as string,
            question: this.searchForm.question,
            tid: this.searchForm.tid,
            difficulty: this.searchForm.difficulty,
            grade: this.searchForm.grade,
            level: this.searchForm.level,
            unit: this.searchForm.unit,
            active: this.searchForm.active,
            token: this.token,
        }
        const data = await QuizModel.getQuestionList(item) as response;
        Common.checkToken(data.ERR_CODE as number);
        this.num = data.num as number;
        this.questionList = data.list as object;
        this.pageHtml = data.pageHtml as string;
    }

    private async getLevel() {
        this.searchForm.level = '';
        this.searchForm.unit = '';
        const grade = this.searchForm.grade;
        this.levelList = await Common.getLevel(grade);
        this.unitList = {};
    }

    private async getUnit() {
        this.searchForm.unit = '';
        this.unitList = await Common.getUnit();
    }

    private delQuestion(id: number) {
        this.$emit("openConfirmDelete", true);
        this.qqid = id;
    }

    private async deleteQuestion() {
        if (!this.doubleClick) {
            this.doubleClick = true;

            const item = {
                qqid: this.qqid,
                token: this.token,
            }
            this.errNo = await QuizModel.delQuestion(item);
            this.$emit("closeConfirmDelete", false);
            Common.checkToken(this.errNo);
            this.errorMsg = ErrorMessage[this.errNo];
            this.openLB = true;
            this.doubleClick = false;
        }
    }

    private closeLB() {
        this.openLB = false;
        if (this.errNo === ErrorCode.Success) {
            this.$router.go(0); //reload page
        }
    }
}
