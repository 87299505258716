import { Vue } from "vue-property-decorator";

import { CommonModel, QuizModel } from "@/models";
import { ErrorCode } from "@/constant";

type response = { [key: string]: unknown };

class Common extends Vue {
  private static sInstance: Common;
  private token = window.localStorage.getItem('adminToken') as string;
  private admin_identity = window.localStorage.getItem('admin_identity') as string;

  public static get instance(): Common {
    if (!Common.sInstance) {
      Common.sInstance = new Common();
    }

    return Common.sInstance;
  }

  public logout() {
    // Cookie.delCookie("adminToken", '/admin');
    // Cookie.delCookie("admin_aaid", '/admin');
    // Cookie.delCookie("admin_name", '/admin');
    // Cookie.delCookie("admin_identity", '/admin');
    // Cookie.delCookie("admin_sid", '/admin');
    // Cookie.delCookie("admin_school", '/admin');
    // Cookie.delCookie("admin_authority", '/admin');
    window.localStorage.clear();

    window.location.href = "/admin/login";
  }

  public checkToken(errNo: number) {
    if (errNo === ErrorCode.InvalidToken) {
      this.logout();
      return;
    }
    return;
  }

  //取得級數
  public async getGrade() {
    const item: { [key: string]: string } = {
      token: this.token,
    }
    const data = await CommonModel.getGrade(item) as response;
    this.checkToken(data.ERR_CODE as number);
    return data.list as object;
  }

  //取得該級數下的等級
  public async getLevel(grade: string) {
    const item: { [key: string]: string } = {
      grade: grade,
      token: this.token,
    }
    const data = await CommonModel.getLevel(item) as response;
    this.checkToken(data.ERR_CODE as number);
    return data.list as object;
  }

  //取得該等級下的單元
  public async getUnit() {
    const list: response = {};
    for (let i = 1; i <= 25; i++) {
      list[i] = { unit: 'Unit-'+i };
    }

    if (window.location.pathname == '/admin/quiz/question/add'
    && (this.admin_identity == '2' || this.admin_identity == '3')
    ) {
      return list;
    }

    if (window.location.pathname == '/admin/quiz/question/edit'
    && (this.admin_identity == '2' || this.admin_identity == '3')
    ) {
      return list;
    }

    if (window.location.pathname == '/admin/homework/question/add'
    && (this.admin_identity == '2' || this.admin_identity == '3')
    ) {
      return list;
    }

    if (window.location.pathname == '/admin/homework/question/edit'
    && (this.admin_identity == '2' || this.admin_identity == '3')
    ) {
      return list;
    }

    list[26] = { unit: 'Midterm Exam' };
    list[27] = { unit: 'Final Exam' };
    list[28] = { unit: 'VIDEO Q' };
    list[29] = { unit: 'WORKSHOP' };

    return list;
  }

  //取得所有縣市
  public async getAllCity() {
    const item: { [key: string]: string } = {
      token: this.token,
    }
    const data = await CommonModel.getAllCity(item) as response;
    this.checkToken(data.ERR_CODE as number);
    return data.list as object;
  }

  //取得該縣市之鄉鎮市區
  public async getRegion(cid: string) {
    const item: { [key: string]: string } = {
      cid: cid,
      token: this.token,
    }
    const data = await CommonModel.getRegion(item) as response;
    this.checkToken(data.ERR_CODE as number);
    return data.list as object;
  }

  //取得有分校之縣市
  public async getSchoolCity() {
    const item: { [key: string]: string } = {
      token: this.token,
    }
    const data = await CommonModel.getSchoolCity(item) as response;
    this.checkToken(data.ERR_CODE as number);
    return data.list as object;
  }

  //取得 權限為分校之該分校之縣市
  public async getCity(sid: string) {
    const item: { [key: string]: string } = {
      sid: sid,
      token: this.token,
    }
    const data = await CommonModel.getCity(item) as response;
    this.checkToken(data.ERR_CODE as number);
    return data.list as object;
  }

  //取得該縣市之分校
  public async getSchool(cid: string) {
    const item: { [key: string]: string } = {
      cid: cid,
      token: this.token,
    }
    const data = await CommonModel.getSchool(item) as response;
    this.checkToken(data.ERR_CODE as number);
    return data.list as object;
  }

  // 取得 該分校的班級列表
  public async getClass(type: number, sid: string, clid: number) {
    const item: { type: number, sid: string, clid: number, token: string } = {
      type: type, //2全部
      sid: sid,
      clid: clid,
      token: this.token,
    }
    const data = await QuizModel.getClass(item) as response;
    this.checkToken(data.ERR_CODE as number);
    return data.list as object;
  }

  // 取得 該班級的老師列表
  public async getTeacher(type: number, clid: string, tid: number) {
    const item: { type: number, clid: string, tid: number, token: string } = {
      type: type, //1上架的 2全部
      clid: clid,
      tid: tid,
      token: this.token,
    }
    const data = await QuizModel.getClassTeacher(item) as response;
    this.checkToken(data.ERR_CODE as number);
    return data.list as object;
  }
}

const common = Common.instance;

export { common as Common };

